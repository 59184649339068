<!-- 交易商品详情 -->
<template>
  <div class="trading-details content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: tradingType == 'patent' ? '/trading/patent' : '/trading/trademark' }">
        {{ tradingTypeText }}资产包交易
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ tradingTypeText }}资产包详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <section class="basic-info back-F6F7FB">
      <h3 class="info-title mb-30">{{ product_info.name }}</h3>
      <div class="activity-basic flex">
        <el-image class="info-img border-r4 mr-30" :src="$base + product_info.path" fit="contain">
          <div slot="error" style="min-width:200px" class="image-slot">
            <el-image :src="require('@/assets/images/trading-default.png')"></el-image>
          </div>
        </el-image>
        <div class="flex-1 flex-column justify-between">
          <div class="info-text">{{ product_info.details }}</div>
          <div class="align-center mt-30">
            <p class="align-center mr-30">
              <span> 价格:</span>
              <span class="ml-20" style="font-size: 24px;color: #409EFF;">{{ product_info.price }}</span>
            </p>
            <p class="align-center mr-30">
              <span> 咨询电话:</span>
              <span class="ml-20" style="font-size: 24px;color: #409EFF;">{{ this.$store.getters.configs.hotline_phone
              }}</span>
            </p>
            <el-button type="primary" size="small" round @click="submitOrder">立即下单</el-button>
          </div>
        </div>
      </div>
    </section>
    <p class="mtb-30">{{ tradingTypeText }}资产包列表</p>
    <List :data="listData" :total="pageTotal" @page="changePage">
      <template v-slot="{ item }">
        <router-link :to="{ path: `/trading/${tradingType}_details`, query: { type: tradingType, id: item.id } }"
          class="flex">
          <el-image class="item-img flex-1 border-r4" :src="$base + item.path" fit="contain">
            <div slot="error" class="image-slot">
              <el-image :src="require('@/assets/images/trading-default.png')"></el-image>
            </div>
          </el-image>
          <div class="item-info ml-20 flex-1 flex-column justify-around">
            <h4 class="item-title text-ellipsis-lines">
              {{ item.name }}
            </h4>
            <el-descriptions v-if="tradingType == 'patent'" :column="3" :colon="false" labelClassName="info-label"
              contentClassName="info-content">
              <el-descriptions-item label="专利号">{{ item.ano }}</el-descriptions-item>
              <el-descriptions-item label="专利权人">{{ item.patentee }}</el-descriptions-item>
              <el-descriptions-item label="专利类型">{{ item.patent_type }}</el-descriptions-item>
              <el-descriptions-item label="领域类型">{{ item.domain_type }}</el-descriptions-item>
              <el-descriptions-item label="有效日期">{{ item.expired_at }}</el-descriptions-item>
              <el-descriptions-item label="法律状态">{{ item.law_state }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions v-else :column="3" :colon="false" labelClassName="info-label"
              contentClassName="info-content">
              <el-descriptions-item label="注册号">{{ item.ano }}</el-descriptions-item>
              <el-descriptions-item label="国际分类"> {{ item.domain_type }}</el-descriptions-item>
              <el-descriptions-item label="类似群组"> {{ item.group }}</el-descriptions-item>
              <el-descriptions-item label="有效日期">{{ item.expired_at }}</el-descriptions-item>
              <el-descriptions-item label="商标状态"> {{ item.law_state }}</el-descriptions-item>
              <el-descriptions-item label="合作类型"> {{ item.feat_type }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </router-link>
      </template>
    </List>
  </div>
</template>
<script>
import * as TradingApi from '@/api/trading'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
export default {
  name: 'TradingPackDetails',
  components: {
    LoadMore,
    List,
  },
  data () {
    return {
      tradingType: 'patent',
      listData: [],
      page: 1,
      pageTotal: 0,
      product_info: {}
    }
  },
  computed: {
    tradingTypeText () {
      return this.tradingType == 'patent' ? '专利' : '商标'
    }
  },
  mounted () {
    this.tradingType = this.$route.query.type
    this.fetchPackProduc()
    this.getPatentProductList()
  },
  methods: {
    // 获取商品详情
    async fetchPackProduc () {
      try {
        var { data } = await TradingApi.fetchPackProduc(this.$route.query.id)
        data['price'] = data['price'] == 0 ? '面议' : data['price']
        this.product_info = data
      } catch (error) { }
    },
    // 获取商品列表
    async getPatentProductList () {
      let queryForm = {
        page: this.page,
        pack_id: this.$route.query.id
      }
      try {
        let { data } = this.tradingType == 'patent' ?
          await TradingApi.getPatentProductList(queryForm) :
          await TradingApi.getTrademarkProductList(queryForm)

        this.listData = data.data
        this.pageTotal = data.total
      } catch (error) { }
    },

    submitOrder () {
      this.$confirm('确定下单吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(async () => {
        let queryForm = {
          type: 'AssetPack',
          id: this.$route.query.id
        }
        const { code, msg } = await TradingApi.assetOrder(queryForm)
        if (code == 200) {
          this.$message.success('下单成功');
          this.$router.replace('/my/order/trading')
        } else {
          this.$message.error(msg);
        }
      }).catch(() => { });
    },
    // 切换分页
    changePage (page) {
      this.page = page
      this.getPatentProductList()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .basic-info {
  padding: 40px;

  .info-img {
    width: 200px;
  }

  .info-title {
    font-size: 24px;
  }

  .info-text {
    line-height: 20px;
    color: #666;
    font-size: 14px;
  }

  .info-label {
    font-size: 16px;
  }

  .info-content {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
}

::v-deep .item {
  height: 150px;

  .item-info {
    width: 45%;
  }
}
</style>